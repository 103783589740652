import Cookies from "js-cookie";

import {
  REMOVE_ITEM,
  ADD_TO_CART,
  INCREASE,
  DECREASE,
  CHECKOUT,
  PROMO,
  CLEAR,
  ADD_NOTE,
} from "./CartTypes.js";

// Set cookie
const Cookie = (cartItems) => {
  const filteredCartItems = cartItems.map((item) => ({
    productItems: {
      id: item.productItems.id,
      name: item.productItems.name,
      price: item.productItems.price,
    },
    quantity: item.quantity,
    note: item.note ? item.note : "",
  }));

  Cookies.set(
    "cartItems",
    JSON.stringify(filteredCartItems.length > 0 ? filteredCartItems : [])
  );
};

// calculate the total price and total quantity of the cart
export const sumItems = (cartItems, promo) => {
  Cookie(cartItems);

  let itemCount = cartItems.reduce(
    (total, product) => total + product.quantity,
    0
  );
  let subTotal = cartItems.reduce(
    (total, product) => total + product.productItems.price * product.quantity,
    0
  );

  let total = subTotal;

  if (promo != undefined) {
    total = total * (1 - promo / 100);
  }
  return { itemCount, total, subTotal };
};

const CartReducer = (state, action) => {
  switch (action.type) {
    // ADD_TO_CART to add the item to the cartItems array
    case ADD_TO_CART:
      if (
        !state.cartItems.find(
          (item) => item.id === action.payload.productItems.id
        )
      ) {
        state.cartItems.push({
          ...action.payload,
          quantity: action.payload.quantity,
        });
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    // REMOVE_ITEM to remove the item from the cartItems array
    case REMOVE_ITEM:
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter(
            (item) => item.productItems.id !== action.payload.id
          )
        ),
        cartItems: [
          ...state.cartItems.filter(
            (item) => item.productItems.id !== action.payload.id
          ),
        ],
      };

    // INCREASE to increase the quantity of the item in the cartItems array
    case INCREASE:
      state.cartItems[
        state.cartItems.findIndex(
          (item) => item.productItems.id === action.payload.id
        )
      ].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems, state.appliedPromo.percent),
        cartItems: [...state.cartItems],
      };

    // DECREASE to decrease the quantity of the item in the cartItems array
    case DECREASE:
      state.cartItems[
        state.cartItems.findIndex(
          (item) => item.productItems.id === action.payload.id
        )
      ].quantity--;
      return {
        ...state,
        ...sumItems(state.cartItems, state.appliedPromo.percent),
        cartItems: [...state.cartItems],
      };

    // PROMO to check the promo code
    case PROMO:
      if (action.payload.isValid && !state.hasDiscount) {
        return {
          ...state,
          ...sumItems(state.cartItems, action.payload.validCodes[0].percent),
          hasDiscount: true,
          promoCodeStatus: "valid",
          appliedPromo: action.payload.validCodes[0],
        };
      }
      return {
        ...state,
        promoCodeStatus: "invalid",
      };

    // NOTE to check the note item
    case ADD_NOTE:
      let existingItem = state.cartItems.find(
        (item) => item.productItems.id === action.payload.productItems.id
      );

      if (existingItem) {
        existingItem.note = action.payload.notes;
      } else {
        state.cartItems.push({
          ...action.payload,
          note: action.payload.notes,
        });
      }

      return {
        ...sumItems(state.cartItems),
        ...state,
        cartItems: [...state.cartItems],
      };

    // CHECKOUT to clear the cartItems array and set checkout to true
    case CHECKOUT:
      return {
        cartItems: [],
        checkout: true,
        promoCodeStatus: "",
        ...sumItems([]),
      };

    //CLEAR to clear the cartItems array
    case CLEAR:
      return {
        cartItems: [],
        ...sumItems([]),
      };

    default:
      return state;
  }
};

export default CartReducer;
