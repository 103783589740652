import React from "react";

// import components
import { SearchBar, Banner } from "../components";

// import sections
import { MenuList } from "../sections";

const Home = () => {
  return (
    <div className="overflow-x-hidden overflow-y-hidden min-h-screen pt-4">
      <SearchBar />
      <Banner />
      <MenuList />
      {/* <PopularMenu /> */}
    </div>
  );
};

export default Home;
