import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";

import Logo from "../assets/logo.png";

import CartContext from "../context/Cart/CartContext";

const Navbar = () => {
  const { cartItems } = useContext(CartContext);
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const cartTotal = cartItems
    .map((product) => {
      return product.quantity;
    })
    .reduce((a, b) => (a += b), 0);

  const [stickyClass, setStickyClass] = useState("relative");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100
        ? setStickyClass("shadow-md")
        : setStickyClass("shadow-none");
    }
  };

  return (
    <nav className={`sm:px-16 px-4 sticky top-0 z-50 bg-white ${stickyClass}`}>
      <div className="container py-5 mx-auto flex justify-between">
        <Link to={"/" + companyData.encryptedID + "/" + companyData.tableID}>
          <img src={Logo} alt="logo" className="w-[180px] object-contain" />
        </Link>
        <Link
          to={
            "/" + companyData.encryptedID + "/" + companyData.tableID + "/cart"
          }
        >
          <div className="bg-[#F5F5F5] rounded-full w-[40px] h-[40px] flex justify-center items-center">
            <div className="py-4 relative">
              <FiShoppingCart className="text-[#404040] text-xl" />
              <span className="absolute top-0 right-3 -mr-6">
                <div className="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                  {cartTotal}
                </div>
              </span>
            </div>
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
