import React, { useContext, useState } from "react";

import { FiShoppingCart } from "react-icons/fi";
import { BiPurchaseTag, BiCheckCircle, BiXCircle } from "react-icons/bi";

import { Link, useNavigate } from "react-router-dom";

import CartContext from "../context/Cart/CartContext";
import { CartItems, PlaceholderCard } from "../components";

import { formatCurrency } from "../utils";

const Cart = () => {
  const {
    cartItems,
    applyPromoCode,
    handleCheckout,
    total,
    subTotal,
    promoCodeStatus,
  } = useContext(CartContext);

  const [promoCode, setPromoCode] = useState("");
  const navigate = useNavigate();

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const onCheckout = async () => {
    if (cartItems.length === 0) {
      console.log("cart is empty");
    } else {
      try {
        const response = await handleCheckout();
        navigate(
          `/${
            companyData.encryptedID + "/" + companyData.tableID
          }/success?orderCode=${response.data.data.order_code}`
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const MAX_PLACEHOLDER_CARDS = 3;
  const numCartItems = cartItems.length;
  const numPlaceholderCards = Math.max(1, MAX_PLACEHOLDER_CARDS - numCartItems);
  return (
    <div className="sm:px-16 px-4 min-h-fit relative">
      <h1 className="text-center text-xl font-bold text-[#404040] my-4">
        Daftar Keranjang Anda
      </h1>

      <div className="items flex flex-wrap gap-y-4 items-center justify-center">
        {cartItems.map((payload, index) => (
          <div
            key={index}
            className="w-full bg-[#F5F5F5] rounded-lg px-3 py-3 flex"
          >
            <CartItems payload={payload} />
          </div>
        ))}
        {[...Array(numPlaceholderCards)].map((_, index) => (
          <PlaceholderCard key={index} />
        ))}
      </div>

      <div className="relative mx-4">
        <div className="relative mt-8">
          <div className="absolute top-5 left-3">
            <BiPurchaseTag className="text-xl text-[#BEBEBE]" />
            {/* <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i> */}
          </div>
          <input
            type="text"
            className="h-14 w-full pl-10 pr-20 bg-[#F5F5F5] rounded-lg z-0 focus:shadow focus:outline-none read-only:bg-gray-200/60"
            placeholder="Promo code"
            value={promoCode}
            onChange={(event) => setPromoCode(event.target.value)}
            readOnly={promoCodeStatus === "valid" ? true : false}
          />
          <div className="absolute top-2 right-3">
            <button
              disabled={
                promoCodeStatus === "valid" ||
                promoCode === "" ||
                cartItems.length == 0
                  ? true
                  : false
              }
              onClick={() => applyPromoCode(promoCode)}
              className="h-10 w-auto px-4 text-white rounded-lg disabled:bg-gray-400/60 bg-[#6AA29B] font-lato"
            >
              Pasang Promo
            </button>
          </div>
        </div>

        {promoCodeStatus !== "" &&
          (promoCodeStatus === "valid" ? (
            <p className="text-sm text-[#606060] mt-1">
              <BiCheckCircle className="inline text-green-600" /> Kode promo
              berhasil dipakai
            </p>
          ) : (
            <p className="text-sm text-[#606060]">
              <BiXCircle className="inline text-red-600" /> Kode promo tidak
              ditemukan
            </p>
          ))}

        <div className="mt-6 border-b border-[#E7E7E7] pb-3 font-lato">
          <div className="flex justify-between">
            <h2>Sub Total</h2>
            <p>{formatCurrency(subTotal)}</p>
          </div>
          <div className="flex justify-between">
            <h2>Discount</h2>
            <p>- {formatCurrency(subTotal - total)}</p>
          </div>
        </div>
        <div className="flex justify-between mt-2 font-lato">
          <h2 className="font-extrabold">Total</h2>
          <p className="font-extrabold">{formatCurrency(total)}</p>
        </div>

        <div className="flex justify-center">
          <Link to="#">
            <button
              onClick={onCheckout}
              disabled={cartItems.length === 0 ? true : false}
              className="text-white bg-[#68B9FC] hover:bg-[#68B9FC]/80 rounded text-md px-6 py-2 text-center inline-flex items-center w-auto mx-auto justify-center mt-8 mb-3 shadow-lg font-bold font-lato disabled:bg-gray-400/60"
            >
              <FiShoppingCart className="mr-2 -ml-1 text-xl" />
              Checkout Sekarang
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
