import React from "react";
import { Link } from "react-router-dom";
import { CardMenu } from "../components";

import NotFound from "../assets/not-found.png";

const MenuItems = ({ products, category }) => {
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  // product slider
  // const settings = {
  //   dots: true,
  //   arrows: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   rows: 2,
  //   initialSlide: 0,
  //   currentSlide: 0,
  //   // autoplay: true,
  // };
  return (
    <>
      {products.length ? (
        <section className="py-7 overflow-x-hidden overflow-y-hidden">
          <h1 className="text-center font-bold mb-5 mt-3 text-[18px]">
            Daftar Menu "{category.name}"
          </h1>

          <div
            className="py-2 md:px-3 h-auto pb-12 -mb-10 lg:rounded-xl"
            data-aos="zoom-in"
          >
            <div className="grid gap-5 mx-3 grid-cols-2 lg:grid-cols-12">
              {products.map((menu, index) => (
                <Link
                  key={index}
                  to={
                    "/" +
                    companyData.encryptedID +
                    "/" +
                    companyData.tableID +
                    "/" +
                    menu.id
                  }
                  className="flex flex-col"
                >
                  <CardMenu {...menu} index={index + 1} />
                </Link>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <div className="mt-2">
          <img
            src={NotFound}
            alt="not found"
            className="w-52 mx-auto"
            data-aos="zoom-in"
          />
          <h1 className="text-center text-lg font-lato" data-aos="fade-up">
            Mohon maaf menu belum tersedia.
          </h1>
        </div>
      )}
    </>
  );
};

export default MenuItems;
