import { useReducer } from "react";
import CartContext from "./CartContext";
import CartReducer from "./CartReducer";
import { sumItems } from "./CartReducer";
import Cookies from "js-cookie";
import axios from "../../axios.js";

const cart = Cookies.get("cartItems")
  ? JSON.parse(Cookies.get("cartItems"))
  : [];

const CartState = ({ children }) => {
  const initialState = {
    cartItems: cart,
    ...sumItems(cart),
    checkout: false,
    hasDiscount: false,
    promoCodeStatus: "",
    appliedPromo: "",
  };

  const [state, dispatch] = useReducer(CartReducer, initialState);
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  //Function to handle when an item is added from the store into the Cart
  const addToCart = (payload) => {
    dispatch({ type: "ADD_TO_CART", payload });
  };

  //Function to handle when an item that is in the cart is added again will be increase the qty item
  const increase = (payload) => {
    dispatch({ type: "INCREASE", payload });
  };

  //Function to handle when an item is removed from the cart
  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  //Function to handle add note for item
  const addNote = (payload) => {
    dispatch({ type: "ADD_NOTE", payload });
  };

  //Function to remove an item from the cart
  const removeFromCart = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  //Function to clear the cart
  // const clearCart = () => {
  //   dispatch({ type: "CLEAR" });
  // };

  //Function to apply promo code
  const applyPromoCode = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/promo/${companyData.companyID}/all?status=active&code=${payload}`
        )
        .then((response) => {
          resolve(response);

          const validCodes = response.data.data;
          if (validCodes.length) {
            dispatch({
              type: "PROMO",
              payload: {
                isValid: true,
                validCodes,
              },
            });
          } else {
            dispatch({
              type: "PROMO",
              payload: {
                isValid: false,
              },
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //Function to handle when the user clicks the checkout button
  const handleCheckout = () => {
    const order = state.cartItems.map((item) => ({
      product_id: item.productItems.id,
      price: item.productItems.price,
      order_qty: item.quantity,
      order_note: item.note ? item.note : "-",
    }));

    const formData = new FormData();
    formData.set("company_id", companyData.companyID);
    formData.set("order_table", companyData.tableID);
    if (state.appliedPromo) {
      formData.set("promo_id", state.appliedPromo.id);
    }
    formData.set("order_status", "pending");
    formData.set("device", "cashier");
    formData.append("order_items", JSON.stringify(order));

    return new Promise((resolve, reject) => {
      axios
        .post(`/api/order/add`, formData)
        .then((response) => {
          resolve(response);
          dispatch({ type: "CHECKOUT" });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <CartContext.Provider
      value={{
        showCart: state.showCart,
        cartItems: state.cartItems,
        addToCart,
        removeFromCart,
        increase,
        decrease,
        handleCheckout,
        addNote,
        applyPromoCode,
        ...state,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartState;
