import React from "react";

import Logo from "../assets/logo-loading.png";

const Loading = () => {
  return (
    <div id="loading-bg">
      <div className="loading-logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default Loading;
