import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter as Router } from "react-router-dom";
import CartState from "./context/Cart/CartState";
import MenuState from "./context/Menu/MenuState";
import CompanyState from "./context/Company/CompanyState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    {/* <React.StrictMode> */}
    <CompanyState>
      <MenuState>
        <CartState>
          <App />
        </CartState>
      </MenuState>
    </CompanyState>
    {/* </React.StrictMode> */}
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
