import React, { useState } from "react";

import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(
        `/${
          companyData.encryptedID + "/" + companyData.tableID
        }/search?q=${searchTerm}`
      );
    }
  };
  return (
    <section className="sm:px-16 px-4" data-aos="fade-up">
      <div className="relative flex items-center w-full h-12 rounded-2xl focus-within:shadow-lg border-[0.7px] border-[#DDDDDD] overflow-hidden">
        <div className="grid place-items-center h-full w-12 text-gray-300">
          <CiSearch className="text-2xl text-[#606060]" />
        </div>

        <input
          className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
          type="text"
          id="search"
          placeholder="Search Menu ..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </section>
  );
};

export default SearchBar;
