import React from "react";
import { Routes, Route } from "react-router-dom";

// import pages
import Home from "../pages/Home";
import Detail from "../pages/Detail";
import Search from "../pages/Search";
import Cart from "../pages/Cart";
import Success from "../pages/Success";

const Router = () => {
  return (
    <div>
      <Routes>
        <Route path="/:companyID/:tableID" element={<Home />} />
        <Route path="/:companyID/:tableID/:id" element={<Detail />} />
        <Route path="/:companyID/:tableID/search" element={<Search />} />
        <Route path="/:companyID/:tableID/cart" element={<Cart />} />
        <Route path="/:companyID/:tableID/success" element={<Success />} />
      </Routes>
    </div>
  );
};

export default Router;
