import { useReducer, useState } from "react";
import MenuContext from "./MenuContext";
import MenuReducer from "./MenuReducer";

import {
  FIND_PRODUCT,
  GET_CATEGORIES,
  DETAIL_PRODUCT,
  SEARCH_PRODUCT,
  RECOMMENDED_PRODUCT,
  GET_PROMO,
} from "./MenuTypes";

import axios from "../../axios.js";

const MenuState = ({ children }) => {
  const initialState = {
    categoryItems: [],
    setProducts: () => {},
    products: [],
    productDetail: [],
    productSearch: [],
    recommendedProducts: [],
    promoData: [],
    error: null,
    isActive: false,
  };

  const [products, setProducts] = useState([]);

  const [state, dispatch] = useReducer(MenuReducer, initialState);

  //Function to handle when an item is added from the store into the Cart
  const getCategories = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/category/${payload.companyID}/all`)
        .then((response) => {
          resolve(response);
          dispatch({ type: GET_CATEGORIES, response });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const findProduct = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/product/category/${payload.id}`)
        .then((response) => {
          resolve(response);
          dispatch({ type: FIND_PRODUCT, response });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const detailProduct = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/product/${payload}`)
        .then((response) => {
          axios
            .get(
              `/api/product/${companyData.companyID}/all?categoryId=${response.data.data.category_id}&limit=4`
            )
            .then((response) => {
              dispatch({ type: RECOMMENDED_PRODUCT, response });
            });
          resolve(response);
          dispatch({ type: DETAIL_PRODUCT, response });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const searchProduct = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/product/${payload}/all`)
        .then((response) => {
          resolve(response);
          dispatch({ type: SEARCH_PRODUCT, response });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getPromo = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/promo/${payload.companyID}/all?status=active&promoType=4`)
        .then((response) => {
          resolve(response);
          dispatch({ type: GET_PROMO, response });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <MenuContext.Provider
      value={{
        categoryItems: state.categoryItems,
        products: products,
        getCategories,
        findProduct,
        detailProduct,
        setProducts,
        productSearch: state.productSearch,
        searchProduct,
        getPromo,
        promoData: state.promoData,
        ...state,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuState;
