import React, { useContext, useState } from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import CartContext from "../context/Cart/CartContext";
import { formatCurrency } from "../utils";

import SirecaPH from "../assets/sireca-ph.png";

const CartItems = ({ payload }) => {
  const { productItems, quantity, note } = payload;

  const { removeFromCart, increase, decrease, addNote } =
    useContext(CartContext);
  const [showModal, setShowModal] = useState(false);

  const [notes, setNote] = useState(note);

  const storageUrl = process.env.REACT_APP_BASE_URL;
  return (
    <div className="flex w-full items-center">
      <img
        src={
          !productItems.image || productItems.image === "null"
            ? SirecaPH
            : storageUrl + "/uploads/" + productItems.image
        }
        alt=""
        className="bg-white p-2 rounded-2xl w-[100px] md:w-[150px]"
      />
      <div className="mx-4 w-full">
        <h1 className="font-bold text-lg font-lato">{productItems.name}</h1>
        <p className="text-sm mb-1 font-lato">{note}</p>
        <p className="font-lato">{formatCurrency(productItems.price)}</p>

        <div className="flex mt-3 gap-2 items-center">
          <button
            onClick={() => setShowModal(true)}
            className="text-[#404040] border border-[#DDDDDD] rounded-full text-sm px-4 py-1 text-center inline-flex items-center mr-2 mb-2"
          >
            <FiEdit className="mr-2 -ml-1" />
            {note === undefined || note === "" ? "Catatan" : "Edit"}
          </button>
          <div className="flex flex-row bg-transparent -mt-2">
            {quantity > 1 ? (
              <button
                onClick={() => decrease(productItems)}
                className="inline-flex items-center justify-center w-5 h-5 transition-colors duration-150 bg-white rounded-full focus:shadow-outline text-black"
              >
                −
              </button>
            ) : (
              <button
                onClick={() => removeFromCart(productItems)}
                className="inline-flex items-center justify-center w-5 h-5 transition-colors duration-150 bg-white rounded-full focus:shadow-outline text-black"
              >
                <FiTrash2 className="text-sm" />
              </button>
            )}

            <input
              type="number"
              className="text-center w-6 flex items-center text-black bg-white rounded-full mx-2"
              name="qty"
              value={quantity}
              readOnly
            ></input>
            <button
              onClick={() => increase(productItems)}
              className="inline-flex items-center justify-center w-5 h-5 transition-colors duration-150 bg-white rounded-full focus:shadow-outline text-black"
            >
              +
            </button>
          </div>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-8">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-start justify-between px-5 py-2 rounded-t ">
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none "
                        onClick={() => setShowModal(false)}
                      >
                        <span className="absolute -top-2 -right-2 rounded-full bg-gray-200 p-1 text-black text-[18px] block outline-none focus:outline-none">
                          <RxCross1 />
                        </span>
                      </button>
                    </div>
                    <div className="relative px-6 py-2 flex-auto">
                      <label>Catatan</label>
                      <input
                        type="text"
                        value={notes}
                        onChange={(e) => setNote(e.target.value)}
                        className="mt-2 w-full h-10 border-[2px] border-gray-300 rounded focus:outline-none focus:border-sky-400 focus:ring- focus:ring-sky-400"
                      />
                    </div>
                    <div className="flex items-center justify-end px-6 py-3 rounded-b">
                      <button
                        className="bg-[#6AA29B] text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                          addNote({ productItems, notes });
                        }}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CartItems;
