import React, { useContext, useEffect } from "react";
import Router from "./router";

// import components
import { Footer, Navbar } from "../src/components";

import AOS from "aos";
import "aos/dist/aos.css";
import CompanyContext from "./context/Company/CompanyContext";
import { decryptCipher } from "./utils";

const App = () => {
  const {
    companyID,
    setCompanyID,
    setTableID,
    tableID,
    encryptedID,
    setEncryptedID,
  } = useContext(CompanyContext);

  useEffect(() => {
    // extract the values from the URL and set the values in the context
    const [, companyIDFromURL, tableIDFromURL] =
      window.location.pathname.split("/");
    setCompanyID(decryptCipher(companyIDFromURL));
    setEncryptedID(companyIDFromURL);
    setTableID(tableIDFromURL);
  }, []);

  const data = JSON.parse(localStorage.getItem("companyData"));
  const companyData = {
    companyID: companyID,
    tableID: tableID,
    encryptedID: encryptedID,
  };
  if (!localStorage.getItem("companyData")) {
    localStorage.setItem("companyData", JSON.stringify(companyData));
  } else if (
    data.companyID == null &&
    data.tableID == null &&
    data.encryptedID == null
  ) {
    localStorage.setItem("companyData", JSON.stringify(companyData));
  } else if (
    data.companyID !== companyID ||
    data.tableID !== tableID ||
    data.encryptedID !== encryptedID
  ) {
    localStorage.setItem("companyData", JSON.stringify(companyData));
  }

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="max-w-[1280px] mx-auto bg-white">
      <Navbar />
      <Router />
      <Footer />
    </div>
  );
};

export default App;
