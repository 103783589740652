import CryptoJS from 'crypto-js'

export const decryptCipher = (cipher) => {
  const iv = CryptoJS.enc.Hex.parse(cipher.substr(0,32))
  const ct = CryptoJS.enc.Hex.parse(cipher.substr(32))
  const key = CryptoJS.enc.Utf8.parse("KaPdSgVkYp3s6v9y$B&E)H+MbQeThWmZ")

  const decrypted = CryptoJS.AES.decrypt({ciphertext: ct}, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv
  })

  return decrypted.toString(CryptoJS.enc.Utf8)
}

export const formatCurrency = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number).split(',')[0];
};
