import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

// import components
import { CardMenu, Loading, SearchBar } from "../components";

import MenuContext from "../context/Menu/MenuContext";

import NotFound from "../assets/not-found.png";

const Search = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const term = query.get("q");

  const { productSearch, searchProduct } = useContext(MenuContext);
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    searchProduct(companyData.companyID).then(() => {
      setLoading(false);
    });
  }, [term]);

  const filteredProducts = productSearch.filter((product) =>
    product.name.toLowerCase().includes(term.toLowerCase())
  );

  return (
    <div className="min-h-screen">
      <SearchBar />
      <h1 className="text-center text-xl font-bold text-[#404040] my-4">
        Hasil Pencarian "{term}"
      </h1>
      <div className="">
        {loading ? (
          <Loading />
        ) : (
          <>
            {filteredProducts.length ? (
              <div className="grid gap-5 mx-3 grid-cols-2 lg:grid-cols-12">
                {filteredProducts.map((menu, index) => (
                  <Link
                    key={index}
                    to={
                      "/" +
                      companyData.encryptedID +
                      "/" +
                      companyData.tableID +
                      "/" +
                      menu.id
                    }
                    className="flex flex-col justify-center"
                  >
                    <CardMenu {...menu} index={index + 1} />
                  </Link>
                ))}
              </div>
            ) : (
              <>
                <div className="mt-3">
                  <img
                    src={NotFound}
                    alt="not found"
                    className="w-52 mx-auto"
                    data-aos="zoom-in"
                  />
                  <h1 className="mx-auto text-center font-lato text-lg">
                    Oops! menu tidak ditemukan.
                  </h1>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Search;
