import React from "react";

const Footer = () => {
  return (
    <div className="text-center p-4 text-[#606060]">
      © 2023 PT SIGI Systems. All rights reserved.
    </div>
  );
};

export default Footer;
