import React, { useState, useContext, useEffect } from "react";

import { FiShoppingCart } from "react-icons/fi";

// import components
import { CardMenu, Loading } from "../components";

import CartContext from "../context/Cart/CartContext";
import { Link, useParams } from "react-router-dom";
import { formatCurrency } from "../utils";
import MenuContext from "../context/Menu/MenuContext";

import SirecaPH from "../assets/sireca-ph.png";

const Detail = () => {
  const { detailProduct, productDetail, recommendedProducts } =
    useContext(MenuContext);
  // Find product by ID parameters
  const { id } = useParams();

  const [quantity, setQuantity] = useState(1);

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const { addToCart, increase, cartItems } = useContext(CartContext);

  const [loading, setLoading] = useState(false);

  const storageUrl = process.env.REACT_APP_BASE_URL;
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  //Check whether the product is in the cart or not
  const isInCart = (productDetail) => {
    return !!cartItems.find(
      (item) => item.productItems.id === productDetail.id
    );
  };

  useEffect(() => {
    setLoading(true);
    detailProduct(id).then(() => {
      setLoading(false);
    });
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className="sm:px-16 bg-[#F0F0F0] h-auto">
          <div className="text-center py-4 mx-4">
            <img
              src={
                !productDetail.image || productDetail.image === "null"
                  ? SirecaPH
                  : storageUrl + "/uploads/" + productDetail.image
              }
              alt=""
              className="mx-auto w-full p-3 rounded-3xl"
              data-aos="zoom-in"
            />
            <h1
              className="font-bold text-2xl mt-3 text-[#404040]"
              data-aos="fade-in"
            >
              {productDetail.name} - {formatCurrency(productDetail.price)}
            </h1>
            <p className="mt-3 font-lato" data-aos="fade-in">
              {productDetail.description === null
                ? "-"
                : productDetail.description}
            </p>
          </div>
          <div className="mt-6 mx-2" data-aos="fade-up">
            <h1 className="font-bold text-xl mt-3">Rekomendasi Menu Lainnya</h1>
            <div className="grid gap-5 mx-1 grid-cols-2 lg:grid-cols-12 m-4">
              {recommendedProducts.map((recomended, index) => (
                <Link
                  key={index}
                  to={
                    "/" +
                    companyData.encryptedID +
                    "/" +
                    companyData.tableID +
                    "/" +
                    recomended.id
                  }
                  className="flex flex-col"
                >
                  <CardMenu key={index} {...recomended} index={index + 1} />
                </Link>
              ))}
            </div>
          </div>
          <div className="rounded-t-3xl rounded-tr-3xl bg-white w-full p-6 flex flex-col justify-center mt-10">
            <div className="flex justify-between items-center">
              <h2 className="font-bold text-2xl text-[#404040]">
                {formatCurrency(productDetail.price)}
              </h2>
              <div className="">
                <div className="h-10 w-32">
                  <div className="flex flex-row h-10 w-full relative bg-transparent rounded-full border-[1px] border-[#DDDDDD]">
                    <button
                      onClick={handleDecrement}
                      className=" w-[100px] cursor-pointer outline-none"
                    >
                      <span className="text-[20px] font-bold">−</span>
                    </button>
                    <input
                      type="number"
                      className="  text-center w-full md:text-basecursor-default flex items-center text-gray-700 font-bold outline-none"
                      name="custom-input-number"
                      value={quantity}
                      readOnly
                    ></input>
                    <button
                      onClick={() => setQuantity(quantity + 1)}
                      className=" w-[100px] cursor-pointer"
                    >
                      <span className="text-[20px] font-bold">+</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() =>
                isInCart(productDetail)
                  ? increase(productDetail)
                  : addToCart({ productItems: productDetail, quantity })
              }
              className="text-white bg-[#68B9FC] hover:bg-[#68B9FC]/80 rounded text-md px-6 py-2 text-center inline-flex items-center w-auto mx-auto justify-center mt-8 shadow-lg font-bold font-lato"
            >
              <FiShoppingCart className="mr-2 -ml-1 text-xl" />
              Tambahkan ke Keranjang
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default Detail;
