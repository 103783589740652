import React, { useContext, useEffect, useState } from "react";

import MenuContext from "../context/Menu/MenuContext";

// import slick carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { MenuItems } from "../components";

import SirecaPH from "../assets/sireca-ph.png";

const MenuList = () => {
  const { getCategories, categoryItems, findProduct, products } =
    useContext(MenuContext);

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const storageUrl = process.env.REACT_APP_BASE_URL;

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2.5,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  const [selectedCategory, setSelectedCategory] = useState(
    categoryItems && categoryItems.length > 0 ? categoryItems[0] : null
  );

  useEffect(() => {
    getCategories({ companyID: companyData.companyID });
  }, [companyData.companyID]);

  useEffect(() => {
    if (categoryItems.length > 0) {
      setSelectedCategory(categoryItems[0]);
    }
  }, [categoryItems]);

  useEffect(() => {
    if (selectedCategory) {
      findProduct(selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <>
      <section className="sm:px-16 mx-2 pt-4" data-aos="fade-up">
        <Slider {...settings}>
          {categoryItems.map((category, index) => (
            <div
              onClick={() => setSelectedCategory(category)}
              key={index}
              className="w-1/3 md:w-full p-2"
            >
              <div
                key={index}
                className={`rounded-xl border-[1px] border-[#DDDDDD] hover:bg-[#68B9FC] hover:text-white  flex flex-row justify-center items-center p-2 ${
                  selectedCategory && selectedCategory.id === category.id
                    ? "bg-[#68B9FC] text-white"
                    : ""
                }`}
              >
                <img
                  src={
                    !category.icon || category.icon === "null"
                      ? SirecaPH
                      : storageUrl + "/uploads/" + category.icon
                  }
                  alt={category.name}
                  className="w-[30px] h-[30px] object-contain rounded-lg"
                />
                <p className="font-bold text-center ml-2 text-sm font-lato">
                  {category.name}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </section>

      <MenuItems products={products} category={selectedCategory} />
    </>
  );
};

export default MenuList;
