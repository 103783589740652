import React, { useContext, useEffect } from "react";

// import slick carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MenuContext from "../context/Menu/MenuContext";

const Banner = () => {
  const { getPromo, promoData } = useContext(MenuContext);

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    getPromo({ companyID: companyData.companyID });
  }, [companyData.companyID]);
  return (
    <Slider {...settings}>
      {promoData.map((promo, index) => (
        <div key={index} className="sm:px-16 px-4 pt-8 pb-4" data-aos="fade-up">
          <div className="h-[150px] bg-bg-banner bg-cover rounded-t-xl rounded-tr-xl p-4">
            <h2 className="text-[#68B9FC] text-2xl font-bold">
              {promo.percent}%
            </h2>
            <div className="flex justify-between">
              <p className="text-xl font-bold">{promo.name}</p>
              <p className="text-xl font-bold text-[#6AA29B]">
                {promo.code ? promo.code : "-"}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Banner;
