import {
  GET_CATEGORIES,
  FIND_PRODUCT,
  DETAIL_PRODUCT,
  SEARCH_PRODUCT,
  RECOMMENDED_PRODUCT,
  GET_PROMO,
} from "./MenuTypes";

const MenuReducer = (state, action) => {
  switch (action.type) {
    case GET_CATEGORIES: {
      return {
        ...state,
        categoryItems: action.response.data.data,
      };
    }
    case FIND_PRODUCT: {
      return {
        ...state,
        products: action.response.data.data,
      };
    }
    case DETAIL_PRODUCT: {
      return {
        ...state,
        productDetail: action.response.data.data,
      };
    }
    case SEARCH_PRODUCT: {
      return {
        ...state,
        productSearch: action.response.data.data,
      };
    }
    case RECOMMENDED_PRODUCT: {
      return {
        ...state,
        recommendedProducts: action.response.data.data,
      };
    }
    case GET_PROMO: {
      return {
        ...state,
        promoData: action.response.data.data,
      };
    }

    default:
      return state;
  }
};

export default MenuReducer;
