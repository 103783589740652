import { useState } from "react";
import CompanyContext from "./CompanyContext";

const CompanyState = ({ children }) => {
  const [companyID, setCompanyID] = useState(null);
  const [tableID, setTableID] = useState(null);
  const [encryptedID, setEncryptedID] = useState(null);

  return (
    <CompanyContext.Provider
      value={{
        companyID,
        setCompanyID,
        tableID,
        setTableID,
        encryptedID,
        setEncryptedID,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyState;
