import React from "react";
import SirecaPH from "../assets/sireca-ph.png";

import { BsCartPlus } from "react-icons/bs";
import { Link } from "react-router-dom";

const PlaceholderCard = () => {
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  return (
    <Link
      to={"/" + companyData.encryptedID + "/" + companyData.tableID}
      className="w-full relative bg-[#F5F5F5] rounded-lg px-3 py-3 flex"
    >
      <div className="bg-gray-600 w-full h-full rounded-lg bg-opacity-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
      <div className="flex w-full items-center">
        <img
          src={SirecaPH}
          alt="sireca"
          className="bg-white p-2 rounded-2xl w-[100px] md:w-[150px]"
        />
        {/* <div class="p-2 rounded-2xl w-[100px] md:w-[150px] bg-gray-200 h-full"></div> */}
        <div className="mx-4 w-full">
          <h1 className="font-bold text-lg font-lato">
            <div className="w-full h-3 bg-gray-200 rounded"></div>
          </h1>

          <div className="mt-2 w-full h-3 bg-gray-200 rounded"></div>

          <div className="flex mt-5 gap-2 items-center">
            <div className="mt-2 w-full h-3 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <BsCartPlus className="text-[44px]" />
      </div>
    </Link>
  );
};

export default PlaceholderCard;
