import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import SuccessImg from "../assets/success.png";

const Success = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderCode = queryParams.get("orderCode");

  const companyData = JSON.parse(localStorage.getItem("companyData"));
  return (
    <div className="sm:px-16 px-4 min-h-[600px] flex justify-center items-center">
      <div className="text-center">
        <img
          src={SuccessImg}
          alt="success"
          className="w-[70%] mx-auto mb-3"
          data-aos="zoom-in"
        />
        <div data-aos="fade-up">
          <h1 className="text-2xl font-bold">Terima Kasih!</h1>
          <h1 className="text-xl my-2 font-lato">
            Kode Pesanan:{" "}
            <span className="font-extrabold">
              {orderCode ? orderCode : "-"}
            </span>
          </h1>
          <p className="font-lato">
            Silahkan tunjukan kode pesanan diatas ke kasir.
          </p>
          <Link to={"/" + companyData.encryptedID + "/" + companyData.tableID}>
            <button
              type="button"
              className="text-white bg-[#68B9FC] hover:bg-[#68B9FC]/80 rounded text-md px-6 py-2 text-center inline-flex items-center w-auto mx-auto justify-center mt-8 shadow-lg font-bold font-lato"
            >
              <FiShoppingBag className="mr-2 -ml-1 text-xl" />
              Kembali ke Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Success;
