import React from "react";
import { formatCurrency } from "../utils";
import SirecaPH from "../assets/sireca-ph.png";

const CardMenu = ({ name, price, image }) => {
  const storageUrl = process.env.REACT_APP_BASE_URL;
  return (
    <>
      <div className="flex-1 rounded-2xl shadow-lg items-center bg-white">
        <div className="overflow-hidden h-full">
          <img
            src={
              !image || image === "null"
                ? SirecaPH
                : storageUrl + "/uploads/" + image
            }
            alt={name}
            className="w-full object-cover rounded-2xl"
            loading="lazy"
          />

          <div className="py-4 px-3">
            <h1 className="font-bold text-md font-lato">{name}</h1>
            <h2 className="text-[#6AA29B] font-bold font-lato">
              {formatCurrency(price)}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardMenu;
